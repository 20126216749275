// src/App.js
import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Routes, Route, useParams, useNavigate } from "react-router-dom";
import _ from "lodash";
import "./App.css";
import EmailList from "./components/EmailList";
import EmailDetail from "./components/EmailDetail";
import PasscodeInput from "./components/PasscodeInput";
import { ENDPOINT } from "./config";
const AUTO_RELOAD_TIME = 1; // in minutes
const passcode = "Since2023!"; // Hardcoded passcod




function MainRoute() {
  const [selectedEmailId, setSelectedEmailId] = useState(null);
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [totalEmails, setTotalEmails] = useState(null);
  const [autoReload, setAutoReload] = useState(true)
  const [searchWords, setSearchWords] = useState([])
  const [searchType, setSearchType] = useState("title")
  const navigate = useNavigate();

  const handleEmailClick = (id) => {
    navigate(`/${id}`);

    setSelectedEmailId(id);
  };

  const handlePasscodeSubmit = (inputPasscode) => {
    if (inputPasscode === passcode) {
      setIsLoggedIn(true);
      localStorage.setItem("isLoggedIn", inputPasscode);
    } else {
      alert("Incorrect passcode");
    }
  };

  useEffect(() => {
    const select = emails.find((email) => email._id === selectedEmailId);
    setSelectedEmail(select);
  }, [selectedEmailId, emails]);

  const fetchEmails = useCallback(async () => {
    if(autoReload){
      try {
        const response = await fetch(
          `${ENDPOINT}/emails/get-emails`
        );
        const data = await response.json();
        setTotalEmails(data.totalEmails);
        const newEmails = data?.data || [];
        setAutoReload(false)
        setEmails((prevEmails) => {
          if (!_.isEqual(prevEmails, newEmails)) {
            return newEmails;
          }
          console.log("No new data found");
          return prevEmails;
        });
      } catch (error) {
        console.error("Error fetching emails:", error);
        setAutoReload(false)
      }
    };
    
  }, []);

  useEffect(() => {
    fetchEmails();
    // const intervalId = setInterval(fetchEmails, AUTO_RELOAD_TIME * 60000);
    // return () => clearInterval(intervalId);
  }, [fetchEmails]);

  useEffect(() => {
    const loggedInStatus = localStorage.getItem("isLoggedIn");
    if (loggedInStatus === passcode) {
      setIsLoggedIn(true);
    }
  }, []);

  if (!isLoggedIn) {
    return <PasscodeInput onPasscodeSubmit={handlePasscodeSubmit} />;
  }

  return (
    <div className="app">
      <div className="email-list-container">
        <EmailList
          emails={emails}
          onEmailClick={handleEmailClick}
          setEmails={setEmails}
          totalEmails={totalEmails}
          setTotalEmails={setTotalEmails}
          setAutoReload={setAutoReload}
          reloading={autoReload}
          setSearchWords={setSearchWords}
          setSearchingType={setSearchType}
        />
      </div>
      <div className="email-detail-container">
        <EmailDetail email_data={selectedEmail} searchWords={searchWords} searchType={searchType} />
      </div>
    </div>
  );
}

export default MainRoute;
