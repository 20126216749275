// src/App.js
import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Routes, Route, useParams } from "react-router-dom";
import MainRoute from "./MainRoute";


function App() {
  

  return (
    <Router>
      <Routes>
      <Route path="/" element={<MainRoute />} />
        <Route path="/:id" element={<MainRoute />} />
      </Routes>
    </Router>
  );
}

export default App;
